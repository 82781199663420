import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { Material, Mesh } from 'three';
import { GLTF as GLTFThree } from 'three/examples/jsm/loaders/GLTFLoader';
import { PAGE } from '../App';
import './CanvasContainer.scss';
import { Keyboard } from './objects';
import Camera from './objects/Camera/Camera';
import Football from './objects/Football/Football';
import Pen from './objects/Pen/Pen';

declare module '@react-three/fiber' {
    export interface GLTF extends GLTFThree {
        nodes: Record<string, Mesh>;
        materials: Record<string, Material>;
    }
}

export interface ICanvasProps {
    page: PAGE;
}

/**
 * Returns the canvas and everything that is rendered on the canvas.
 *
 * @returns CanvasContainer using three.js
 */
export const CanvasContainer = (props: ICanvasProps) => {
    const { page } = props;

    /**
     * Position of camera in canvas
     * Docs: https://threejs.org/docs/?q=camera#api/en/cameras/Camera
     */
    const cameraOptions = {
        // Camera position
        position: [0, 0, 100],
        // Camera frame of view
        fov: 50,
    };

    /**
     * Options for the orbit commands
     * Docs: https://threejs.org/docs/#examples/en/controls/OrbitControls
     */
    const orbitOptions = {
        minPolarAngle: Math.PI / 2,
        maxPolarAngle: Math.PI / 2,
        enableZoom: false,
        enablePan: false,
    };

    /**
     * Render
     * @returns a list of objects to be rendered in the canvas.
     */
    const renderMain = (): JSX.Element[] => {
        if (page == PAGE.STUDENT) {
            return [<Pen position={[0, -2, 0]} scale={0.15} key="pen" />];
        }

        if (page == PAGE.PHOTO) {
            return [<Camera position={[0, -14, 0]} scale={3.5} key="camera" />];
        }

        if (page == PAGE.FOOT) {
            return [<Football position={[0, -40, 0]} scale={0.2} key="boots" />];
        }

        return [
            // <MainText color="black" fontSize={5} position={[0, 20, 0]} key="text">
            //     i'm a software engineer
            // </MainText>,
            <Keyboard scale={8} position={[-70, -20, 0]} rotation={[Math.PI / 2, 0, 0]} key="keyboard" />,
        ];
    };

    return (
        <div className="canvas">
            <Canvas camera={cameraOptions as any}>
                <Suspense>
                    <OrbitControls minPolarAngle={orbitOptions.minPolarAngle} maxPolarAngle={orbitOptions.maxPolarAngle} enableZoom={orbitOptions.enableZoom} enablePan={orbitOptions.enablePan} />
                    <group>{renderMain()}</group>
                </Suspense>
                <ambientLight />
                <directionalLight position={[10, 10, 10]} />
                <pointLight position={[10, 10, 10]} />
            </Canvas>
        </div>
    );
};
